import React from "react";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import DescriptionIcon from "@mui/icons-material/Description";
import PaidIcon from "@mui/icons-material/Paid";
import ShareIcon from "@mui/icons-material/Share";
import CodeIcon from "@mui/icons-material/Code";

import ContentMarketing from "../../media/service-images/content-marketing.jpeg";
import SocialMediaImg from "../../media/service-images/social-media.jpg";
import AiImg from "../../media/service-images/ai.jpg";
import WebDesign from "../../media/service-images/web-design.jpg";
import BrandingImg from "../../media/service-images/branding.jpg";
import { useTranslation } from "react-i18next";

export function useServicesData() {
  const { t } = useTranslation();

  return [
    {
      icon: <ShareIcon />,
      image: SocialMediaImg,
      title: t("socialMedia.title"),
      description: t("socialMedia.description"),
    },
    {
      icon: <CodeIcon />,
      image: WebDesign,
      title: t("webDevelopment.title"),
      description: t("webDevelopment.description"),
    },
    {
      icon: <DescriptionIcon />,
      image: AiImg,
      title: t("ai.title"),
      description: t("ai.description"),
    },
    {
      icon: <BrandingWatermarkIcon />,
      image: BrandingImg,
      title: t("branding.title"),
      description: t("branding.description"),
    },
    {
      icon: <PaidIcon />,
      image: ContentMarketing,
      title: t("contentMarketing.title"),
      description: t("contentMarketing.description"),
    },
  ];
}
// Socialmedia
// AI and Automation
// Web design or web development
// Branding
// Marketing
