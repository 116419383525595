import "./what-we-offer.css";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { isDesktop, isMobile } from "react-device-detect";
import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";
import { useServicesData } from "../../../helpers/hooks/useServices";
import { selectScreenSize } from "../../../store/reducers/layoutSlice";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";
import WhatWeOfferCard from "./WhatWeOfferCard";
import { useNavigate } from "react-router-dom";

function WhatWeOffer() {
  const theme = useTheme();
  const { t } = useTranslation();
    const navigate = useNavigate();
  const { HeadingRegular } = useFontSizes();

  const services = useServicesData();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  const [middleIndex, setMiddleIndex] = useState(0);
  const [hasCardsAppeared, setHasCardsAppeared] = useState(false);
  const [hasTitleAppeared, setHasTitleAppeared] = useState(false);
  const [hasDividerAppeared, setHasDividerAppeared] = useState(false);

  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const { ref: sectionRef, inView: sectionInView } = useIntersectionObserver({
    threshold: 0.1,
    rootMargin: "100px 0px",
  });
  const { ref: titleRef, inView: titleInView } = useIntersectionObserver();
  const { ref: dividerRef, inView: dividerInView } = useIntersectionObserver();
  const { ref: cardsRef, inView: cardsInView } = useIntersectionObserver();

  const shiftLeft = () => {
    setMiddleIndex((prev) => (prev === 0 ? services.length - 1 : prev - 1));
  };

  const shiftRight = () => {
    setMiddleIndex((prev) => (prev === services.length - 1 ? 0 : prev + 1));
  };

  const handleCardClick = (clickedIndex) => {
    const shiftCount =
      (clickedIndex - middleIndex + services.length) % services.length;

    if (shiftCount > 0) {
      Array.from({ length: shiftCount }).forEach(() => shiftRight());
    } else {
      Array.from({ length: Math.abs(shiftCount) }).forEach(() => shiftLeft());
    }
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  // Handle swipe move (prevent vertical scrolling)
  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;

    // Prevent vertical scrolling when swiping left/right
    if (Math.abs(touchEndX.current - touchStartX.current) > 10) {
      e.preventDefault();
    }
  };

  // Handle swipe end
  const handleTouchEnd = () => {
    const touchDiff = touchEndX.current - touchStartX.current;

    if (Math.abs(touchDiff) > 50) {
      if (touchDiff < 0) shiftRight(); // Swipe left
      else shiftLeft(); // Swipe right
    }

    touchStartX.current = 0;
    touchEndX.current = 0;
  };

  // Attach & clean up touch event listener dynamically
  useEffect(() => {
    const section = sectionRef.current;
    if (section) {
      section.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      if (section) {
        section.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(shiftRight, 7000); // Automatically shift cards every 4 seconds
    return () => clearInterval(interval); // Cleanup interval on unmount or reset
  }, [middleIndex]); // Restart timer when middleIndex changes

  const getCardClass = (index) => {
    const offset = (index - middleIndex + services.length) % services.length;

    if (isMobile) {
      // For mobile: Show only the middle card and one adjacent on each side
      if (offset === 0) return "middle mobile-width";
      if (offset === 1) return "adjacent-right mobile-width";
      if (offset === services.length - 1) return "adjacent-left mobile-width";
      return "hidden";
    }

    // For desktop: Show full carousel
    switch (offset) {
      case 0:
        return "middle";
      case 1:
        return "adjacent-right";
      case 2:
        return "smallest-right";
      case services.length - 1:
        return "adjacent-left";
      case services.length - 2:
        return "smallest-left";
      default:
        return "hidden";
    }
  };

  useEffect(() => {
    if (cardsInView) {
      setHasCardsAppeared(true);
    }
  }, [cardsInView]);

  useEffect(() => {
    if (titleInView) {
      setHasTitleAppeared(true);
    }
  }, [titleInView]);

  useEffect(() => {
    if (dividerInView) {
      setHasDividerAppeared(true);
    }
  }, [dividerInView]);

  return (
    <section
      ref={sectionRef}
      className="what-we-offer"
      style={{
        color: theme.palette.text.primary,
        margin: isSmallScreen ? "50px 0" : "60px 0",
        pointerEvents: "none"
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Heading Section */}
      <div
        className="what-we-offer-heading"
        style={{
          fontSize: HeadingRegular,
          alignItems: isSmallScreen && "center",
        }}
      >
        <h2
          ref={titleRef}
          className={`what-we-offer-title ${hasTitleAppeared ? "visible" : ""}`}
          style={{ pointerEvents: "auto", cursor: "pointer" }}
          onClick={() => navigate("/services")}
        >
          {t("whatWeOffer")}
        </h2>
      </div>

      {/* Divider */}
      <div
        ref={dividerRef}
        className={`divider-container-what-we-offer ${
          hasDividerAppeared ? "visible" : ""
        }`}
      >
        <Divider
          sx={{
            width: "95%",
            transition: "width 0.5s ease",
            backgroundColor: theme.palette.text.primaryBorder,
            zIndex: 100,
          }}
        />
      </div>

      {/* Cards Section */}
      <div
        ref={cardsRef}
        className={`cards-container ${hasCardsAppeared ? "visible" : ""}`}
        style={{ margin: isDesktop ? "150px 0" : "70px 0",
          pointerEvents: "auto"
         }}
      >
        {services.map((service, index) => (
          <WhatWeOfferCard
            key={service.id}
            icon={service.icon}
            title={service.title}
            image={service.image}
            description={service.description}
            className={`card-single ${getCardClass(index)} ${
              isMobile ? "is-mobile" : ""
            }`}
            isSmallScreen={isSmallScreen}
            onClick={() => handleCardClick(index)}
          />
        ))}
      </div>
    </section>
  );
}

export default WhatWeOffer;
