import "./videotext.css";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { selectActiveLanguage } from "../../store/reducers/userSlice";
import { useFontSizes } from "../../helpers/hooks/useFontSizes";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import CustomButton from "../other/CustomButton";
import { useNavigate } from "react-router-dom";
import { useIntersectionObserver } from "../../helpers/hooks/useIntersectionObserver";
import { useEffect, useState } from "react";

const translations = {
  en: ["Our Vision.", " Your Solution."],
  tr: ["Vizyonunuz.", " Bizim Çözümümüz."],
  sr: ["Vaša Vizija.", " Naše Rešenje."],
};

function VideoText() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const activeLanguage = useSelector(selectActiveLanguage);
  const { HeadingBig } = useFontSizes();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const textLines = translations[activeLanguage]; // Now an array for two lines
  const [hasCardsAppeared, setHasCardsAppeared] = useState(false);
  const { ref: buttonRef, inView: buttonInView } = useIntersectionObserver();

  useEffect(() => {
    if (buttonInView) {
      setHasCardsAppeared(true);
    }
  }, [buttonInView]);

  return (
    <div className="video-text-container">
      {/* First Line (Top Line) */}
      <div className="text-line first-line">
        {textLines[0].split("").map((char, index) => (
          <span
            style={{
              color: theme.palette.text.secondary,
              fontSize: HeadingBig,
              fontWeight: 700,
              letterSpacing: "0.1em",
              textShadow: "0.022em 0.022em 0.022em #111",
              animationDelay: `${index * 0.08}s`, // Stagger animation
            }}
            key={index}
          >
            {char === " " ? "\u00A0" : char}
          </span>
        ))}
      </div>

      {/* Second Line (Bottom Line) */}
      <div className="text-line second-line">
        {textLines[1].split("").map((char, index) => (
          <span
            style={{
              color: theme.palette.text.secondary,
              fontSize: HeadingBig,
              fontWeight: 700,
              letterSpacing: "0.1em",
              textShadow: "0.022em 0.022em 0.022em #111",
              animationDelay: `${0.5 + index * 0.08}s`, // Stagger animation with 0.5s delay
            }}
            key={index}
          >
            {char === " " ? "\u00A0" : char}
          </span>
        ))}
      </div>

      <div
        ref={buttonRef}
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          bottom: "5%",
          cursor: "pointer",
          zIndex: "55",
        }}
        className={`button-container ${hasCardsAppeared ? "visible" : ""}`}
      >
        <CustomButton
          onClick={() => navigate("/")}
          isCarousel={false}
          isSmallScreen={isSmallScreen}
          icon={false}
          text={t("getStarted")}
        />
      </div>
    </div>
  );
}

export default VideoText;
