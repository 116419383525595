import "./about-us.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useTheme } from "@emotion/react";
import { Divider } from "@mui/material";
import { selectScreenSize } from "../../../store/reducers/layoutSlice";
import { useIntersectionObserver } from "../../../helpers/hooks/useIntersectionObserver";
import { useTranslation } from "react-i18next";
import { useFontSizes } from "../../../helpers/hooks/useFontSizes";
import { useNavigate } from "react-router-dom";

function AboutUs() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { HeadingRegular } = useFontSizes();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;

  const [hasAppeared, setHasAppeared] = useState({
    title: false,
    divider: false,
    whoWeAre1: false,
    whoWeAre2: false,
    whoWeAre3: false,
  });

  const titleObserver = useIntersectionObserver();
  const dividerObserver = useIntersectionObserver();
  const whoWeAreObserver1 = useIntersectionObserver();
  const whoWeAreObserver2 = useIntersectionObserver();
  const whoWeAreObserver3 = useIntersectionObserver();

  useEffect(() => {
    setHasAppeared((prev) => ({
      title: prev.title || titleObserver.inView,
      divider: prev.divider || dividerObserver.inView,
      whoWeAre1: prev.whoWeAre1 || whoWeAreObserver1.inView,
      whoWeAre2: prev.whoWeAre2 || whoWeAreObserver2.inView,
      whoWeAre3: prev.whoWeAre3 || whoWeAreObserver3.inView,
    }));
  }, [
    i18n.language,
    titleObserver.inView,
    dividerObserver.inView,
    whoWeAreObserver1.inView,
    whoWeAreObserver2.inView,
    whoWeAreObserver3.inView,
  ]);

  return (
    <section
      className="what-we-offer"
      style={{
        color: theme.palette.text.primary,
        margin: isSmallScreen ? "50px 0" : "0 0 0 0",
        padding: 0,
      }}
    >
      <div className="what-we-offer-heading" style={{ fontSize: HeadingRegular }}>
        <h2
          ref={titleObserver.ref}
          className={`what-we-offer-title ${hasAppeared.title ? "visible" : ""}`}
          onClick={() => navigate("/about")}
        >
          {t("aboutUs")}
        </h2>
      </div>

      <div ref={dividerObserver.ref} className={`divider-container-what-we-offer ${hasAppeared.divider ? "visible" : ""}`}>
        <Divider sx={{ width: "95%", backgroundColor: theme.palette.text.primaryBorder, margin: isSmallScreen ? "0 0 30px 0" : "0 0 60px 0"  }} />
      </div>
      {[whoWeAreObserver1, whoWeAreObserver2, whoWeAreObserver3].map((observer, index) => (
        <div
          key={index}
          ref={observer.ref}
          className={`who-we-are ${hasAppeared[`whoWeAre${index + 1}`] ? "visible" : ""}`}
          style={{ margin: isSmallScreen ? "30px 0" : "60px 0" }}
        >
          <span style={{ fontSize: HeadingRegular }}>{t(`aboutSection.title${index + 2}`)}</span>
          <div style={{ marginTop: "10px", fontSize: "1rem", textAlign: "left", width: "80%" }}>
            {index < 2 && <p>{t(`aboutSection.description${index + 2}`)}</p>}
            {index === 2 && (
              <ul>
                {t("aboutSection.description4", { returnObjects: true }).map((point, idx) => (
                  <li key={idx}>{point}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      ))}
    </section>
  );
}

export default AboutUs;
