import Footer from "../components/footer/Footer";
import "../App.css";
import "./mainpage.css";
import ModeToggle from "../components/header/components/SettingsMenu";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../components/header-mobile/HeaderMobile";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../store/reducers/layoutSlice";
import VideoText from "../components/mainpage/VideoText";
import { useTheme } from "@emotion/react";
import SmokeBg from "../components/mainpage/SmokeBg";
import WhatWeOffer from "../components/mainpage/what-we-offer/WhatWeOffer";
import AiComingSoon from "../components/mainpage/ai/AiComingSoon";
import AboutUs from "../components/mainpage/about-us/AboutUs";
import { useEffect } from "react";

function Mainpage({ isPlaying, togglePlay }) {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div
      className="App"
      style={{
        background: `linear-gradient(
          240deg, 
          ${theme.palette.primary.mainGradientDARKBG} 0%, 
          ${theme.palette.primary.mainGradientLIGHTBG} 50%, 
          ${theme.palette.primary.mainGradientDarker} 100%
        )`
      }}
      
    >

        <SmokeBg isPlaying={isPlaying} />
      <VideoText isPlaying={isPlaying} togglePlay={togglePlay} />
       <AboutUs />
      <WhatWeOffer />
      {/* <AiComingSoon /> */}
      
      <Footer />
    </div>
  );
}

export default Mainpage;
