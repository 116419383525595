import React from "react";
import "./settings.css";
import PlayPauseButton from "./PlayPauseButton";
import ThemeToggle from "./ThemeToggle";
import LanguageDropdown from "./LanguageDropdown";
import { Divider } from "@mui/material";

function SettingsMenu({
  currentTheme,
  handleModeSelect,
  isPlaying,
  togglePlay,
  theme,
  menuState,
  scrollTrigger,
}) {
  return (
    <div
      className={`settings-menu ${menuState} ${
        scrollTrigger ? "scroll-trigger-active" : ""
      }`}
      style={{
        "--border-color": scrollTrigger ? theme.palette.text.primaryBorder : "transparent",
        "--background-color": scrollTrigger ? theme.palette.primary.squaresHeader : "transparent",
        "--padding": scrollTrigger ? "15px 1.8%" : "15px 1.8%",
        color: theme.palette.text.primary,
      }}
    >
      <div
        className="squares"
        style={{
          "--square-color": theme.palette.primary.mainGradientLighter,
          opacity: scrollTrigger > 0 ? 1 : 0,
        }}
      ></div>
      <div className="settings-menu-container">
        <PlayPauseButton
          isPlaying={isPlaying}
          togglePlay={togglePlay}
          theme={theme}
        />
      </div>
      <div className="settings-menu-container">
        <ThemeToggle
          currentTheme={currentTheme}
          handleModeSelect={handleModeSelect}
          theme={theme}
        />
      </div>
      <div className="settings-menu-container" style={{ minHeight: "0px" }}>
        <LanguageDropdown theme={theme} />
      </div>
      {!scrollTrigger && (
        <Divider
          sx={{
            width: "105%",
            transition: "width 0.5s ease",
            backgroundColor: theme.palette.text.primaryBorder,
            zIndex: 100,
            height: "0.2px",
          }}
        />
      )}
    </div>
  );
}

export default SettingsMenu;
