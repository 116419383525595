import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreation } from "./theme/designTokens";
import { useSelector } from "react-redux";
import { selectActiveTheme } from "./store/reducers/userSlice";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScreenSizeListener from "./helpers/other/ScreenSizeListener";
import { Route, Routes } from "react-router-dom";
import { selectScreenSize } from "./store/reducers/layoutSlice";
import LoadingModal from "./components/other/LoadingModal";
import Mainpage from "./pages/Mainpage";
import HeaderMobile from "./components/header-mobile/HeaderMobile";
import Header from "./components/header/Header";
import { Divider } from "@mui/material";
import Footer from "./components/footer/Footer";
import AboutPage from "./pages/AboutPage";
import ServicesPage from "./pages/ServicesPage";

function App() {
  const themeMode = useSelector(selectActiveTheme);
  const [theme, setTheme] = useState(themeCreation()); 
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  const [isPlaying, setIsPlaying] = useState(true);
  const togglePlay = () => setIsPlaying((prevState) => !prevState);
  
  // Control when to show content
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false); // New state for enabling visibility

  useEffect(() => {
    setTimeout(() => setLoading(false), 4500);
  }, []);

  const handleLogoMove = () => {
    setTimeout(() => {
      setIsVisible(true); 
    }, 500);
  };

  useEffect(() => {
    setTheme(themeCreation(themeMode));
    document.documentElement.style.setProperty(
      "--scrollbar-thumb-color",
      themeCreation(themeMode).palette.text.third
    );
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      {/* Show LoadingModal first */}
      {loading && (
        <LoadingModal
        onComplete={() => setLoading(false)}
        onLogoMove={handleLogoMove}  // Enable visibility when logo moves
        />
      )}
      
      {/* Show the main components as soon as the logo moves */}
      {isVisible && (
        <>
          <ScreenSizeListener />
          {isSmallScreen ? (
            <HeaderMobile isPlaying={isPlaying} togglePlay={togglePlay} />
          ) : (
            <Header isPlaying={isPlaying} togglePlay={togglePlay} />
          )}
          <Routes>
            <Route path="/" element={<Mainpage isPlaying={isPlaying} togglePlay={togglePlay}/>} />
            <Route path="/about" element={<AboutPage isPlaying={isPlaying} togglePlay={togglePlay}/>} />
            <Route path="/services" element={<ServicesPage isPlaying={isPlaying} togglePlay={togglePlay}/>} />
          </Routes>
          {/* <Footer /> */}
        </>
      )}
    </ThemeProvider>
  );
}

export default App;
