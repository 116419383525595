import React from "react";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import "./custom-button.css"; // Separate CSS for button styling
import { useTheme } from "@emotion/react";
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { useSelector } from "react-redux";
import { useFontSizes } from "../../helpers/hooks/useFontSizes";

function CustomButton({
  icon,
  onClick,
  isSmallScreen,
  text,

}) {
  const theme = useTheme();
  const themeMode = useSelector(selectActiveTheme);
    const { ParagraphRegular } = useFontSizes();



  return (
    <button
      className="toggle-layout-button"
      onClick={onClick}
      style={{
        fontSize: ParagraphRegular,
        "--border-color": theme.palette.text.primaryBorderButton, 
        "--button-bg": theme.palette.lightblue.main,
        backgroundColor: theme.palette.lightblue.main,
        color: theme.palette.text.primary,
          "--text-color":
            themeMode === "dark"
              ? "rgb(75, 177, 167)"
              : "rgb(62, 192, 219)",
          "--text-color2":
            themeMode === "dark"
              ? "rgb(145, 135, 87)"
              : "rgba(249, 233, 153, 1)",
          "--text-color3":
            themeMode === "dark"
              ? "rgba(73, 202, 190, 1)"
              : "rgba(97, 226, 210, 1)",
         textShadow: `0px 1px 3px ${theme.palette.text.opposite}80, 
               0px 2px 6px ${theme.palette.text.opposite}30`
      }}
    >
      {text && text}
    </button>
  );
}

export default CustomButton;
