import React, { useEffect, useState } from "react";
import "./loading-modal.css";
import { useSelector } from "react-redux";
import { ReactComponent as LogoBlackS } from "../../media/logo/logo-single-black.svg";
import { ReactComponent as LogoWhiteS } from "../../media/logo/logo-single-white.svg";
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { useTheme } from "@emotion/react";

const LoadingModal = ({ onComplete, onLogoMove }) => { // Receive onLogoMove as a prop
  const theme = useTheme();
  const themeMode = useSelector(selectActiveTheme);
  const [isAnimating, setIsAnimating] = useState(true);
  const [frameComplete, setFrameComplete] = useState(false);
  const [logoMoved, setLogoMoved] = useState(false);
  const [fadeOutBackground, setFadeOutBackground] = useState(false);
  const isDarkMode = themeMode === "dark";

  useEffect(() => {
    setTimeout(() => {
      setFrameComplete(true); // Remove frame after animation

      setTimeout(() => {
        setFadeOutBackground(true); // Start fading background
        setLogoMoved(true); // Move logo
        onLogoMove(); // **Trigger visibility of components in App.js**
        
        setTimeout(() => {
          setIsAnimating(false);
          onComplete();
        }, 1000); // Delay before closing
      }, 600); // Frame disappears smoothly
    }, 2000); // Time for frame animation
  }, [onComplete, onLogoMove]);

  return (
    <div
      className={`loading-overlay ${fadeOutBackground ? "fading" : ""}`}
      style={{
        "--gradient-dark": theme.palette.primary.mainGradientDARKBG,
        "--gradient-light": theme.palette.primary.mainGradientLIGHTBG,
        "--gradient-darker": theme.palette.primary.mainGradientDarker,
        height: "100vh",
      }}
    >
      <div className={`logo-container ${logoMoved ? "moved" : ""}`}>
        <div
          className={`logo-frame ${frameComplete ? "hidden" : ""}`}
          style={{ "--border-color": theme.palette.text.primary }}
        />
        {isDarkMode ? (
          <LogoWhiteS className="loading-logo" />
        ) : (
          <LogoBlackS className="loading-logo" />
        )}
      </div>
    </div>
  );
};

export default LoadingModal;
