import Footer from "../components/footer/Footer";
import "../App.css";
import "./about-page.css";
import ModeToggle from "../components/header/components/SettingsMenu";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../components/header-mobile/HeaderMobile";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../store/reducers/layoutSlice";
import { useTheme } from "@emotion/react";
import SmokeBg from "../components/mainpage/SmokeBg";
import { useEffect } from "react";

function AboutPage({ isPlaying, togglePlay }) {
  const theme = useTheme();
  const screenSize = useSelector(selectScreenSize);
  const isSmallScreen = screenSize === "small" || isMobile;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div
      className="App"
      style={{
        background: `linear-gradient(
          240deg, 
          ${theme.palette.primary.mainGradientDARKBG} 0%, 
          ${theme.palette.primary.mainGradientLIGHTBG} 50%, 
          ${theme.palette.primary.mainGradientDarker} 100%
        )`,
      }}
    >
      <SmokeBg isPlaying={isPlaying} />
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex:10, 
          color: theme.palette.text.primary,
          position: "relative",
          paddingTop:"80px",
          pointerEvents:"none",
        }}
      >
       <h1>About Content Placeholder</h1>
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;
